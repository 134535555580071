.main .content-wrapper .right-container {
	width: 100%;
	padding: 90px 20px 10px 20px;
	min-height: 0;
	max-height: 100vh;
	overflow: scroll;
	scrollbar-width: none;
}

/* main container */
.welcome-container {
	margin: 0 auto;
}

/* ----------------------------------welcome container-------------------------- */
.welcome-container .welcome-content {
	padding: 15px 20px !important;
	min-height: 0;
	max-height: 560px;
	color: #0a0a0a;
	background: linear-gradient(160deg, #e9b5e7 0%, rgba(214, 160, 213, 0.59) 100%) !important;
	border-radius: 1rem;
	box-shadow: 0px 0px 6px -1px rgba(138, 56, 126, 0.53);
}


/* welcome container > user content */
.welcome-container .welcome-content .user-content {
	padding: 6px 5px 10px 0;
	display: flex;
	gap: 10px;
	align-items: center;
	border-bottom: 1px dashed #3a3939;
	
}

.welcome-container .welcome-content .user-content h3 {
	font-weight: 700;
	font-size: 17px;
margin-bottom: 0;
}

.welcome-container .welcome-content .user-content .user-image-container {
	width: 30px;
	height: 30px;
	overflow: hidden;
	border-radius: 50%
}

.welcome-container .welcome-content .user-content .user-image-container img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 50%;
	border: 1px solid #fff;
	padding: 5px;
}

/* welcome container > welcome text container */
.welcome-container .welcome-content .welcome-text-container {
	padding: 9px 0px 0px;
	font-size: 25px;
	font-weight: 600;
}

.welcome-container .welcome-content .welcome-text-container h1 {
	font-size: 21px;
	font-weight: 600;
}
.welcome-text-container h2{
	font-size: 1.2rem;
}
.welcome-container .welcome-content .welcome-text-container p {
	font-family: "Open Sans", sans-serif !important;
	font-weight: 500;
	text-align: justify;
	font-size: 13px;
	line-height: 18px;
	color: #2f2d2d;
}

.welcome-container .welcome-content button {
	color: #fff;
	padding: 6px 15px !important;
	font-weight: 600;
	text-transform: capitalize;
	transition: all 0.3s ease-in-out;
	border-radius: 9px;
	font-family: "Open Sans", sans-serif !important;
	/* background: linear-gradient(165deg, rgb(95, 90, 255) 0%, rgb(196, 87, 176) 0%, rgba(208, 54, 190, 0.89) 98%); */
}

.welcome-container .welcome-content button:hover {
	transform: translateX(2px);
	background-color: var(--hover-color);
}

.welcome-container .assist-content {
	padding: 20px 20px !important;
	min-height: 0;
	max-height: 300px;
	color: #fff;
	background: rgba(235, 234, 234, 0.28);
	border-radius: 1rem;
	height: 210px;
}

.assist-container {
	position: relative;
	box-shadow: 0px 0px 6px -1px rgba(138, 56, 126, 0.53);
	border-radius: 21px;
	overflow: hidden;
	z-index: 10;
}

.assist-container::before {
	content: '';
	width: 150px;
	height: 150px;
	border-radius: 50%;
	background-color: rgba(166, 26, 149, 0.13);
	top: 86px;
	right: -27px;
	position: absolute;
	z-index: -1;
}

.assist-container .support-image img {
	width: 150px;
	position: absolute;
	right: 0;
	bottom: 0;
}

.assist-content {
	padding: 20px 20px !important;
	min-height: 0;
	max-height: 300px;
	color: #fff;
	border-radius: 1rem;
}

.assist-content h3 {
	color: var(--text-color);
}

.assist-content p {
	font-size: 14px;
	line-height: 23px;
	font-family: "Open Sans", sans-serif !important;
	font-weight: 500;
	color: var(--text-color);
}

.assist-content button {
	color: #fff;
	padding: 6px 15px !important;
	font-weight: 600;
	text-transform: capitalize;
	transition: all 0.3s ease-in-out;
	border-radius: 9px;
	font-family: "Open Sans", sans-serif !important;
	/* background: linear-gradient(165deg, rgb(95, 90, 255) 0%, rgb(196, 87, 176) 0%, rgba(208, 54, 190, 0.89) 98%); */
}

.assist-content button:hover {
	transform: translateX(2px);
	background-color: var(--hover-color);
}

.welcome-container .assist-content h3 {
	font-size: 23px;
	font-weight: 600;
}

.welcome-container .assist-content p {
	font-size: 14px;
	line-height: 23px;
	font-family: "Open Sans", sans-serif !important;
	font-weight: 500;
	color: #242424;
	width: 80%;
}

.welcome-container .assist-content button {
	color: #fff;
	padding: 6px 15px !important;
	font-weight: 600;
	background-color: #fff;
	text-transform: capitalize;
	transition: all 0.3s ease-in-out;
	border-radius: 9px;
	font-family: "Open Sans", sans-serif !important;
}

.welcome-container .assist-content button:hover {
	transform: translateX(2px);
}

.quick-links-container {
	margin: 0 auto;
}

.quick-links-container .quick-links {
	text-decoration: none;
	transition: all 0.3s ease-in-out;
}

.quick-links-container .quick-links:hover {
	text-decoration: none;
	transform: translateY(-3px);
}

.quick-links-container .quick-links .link-icon-container svg {
	width: 35px;
	height: 35px;
	color: #fff;
}

.quick-links-container .quick-links .links {
	height: 140px;
	padding: 10px !important;
	border-radius: 1rem;
}

hr {
	border: 1px solid white !important;
	height: 2px;
	background: #fff !important;
	z-index: 100;
	color: #fff !important;
	margin: 0 0 16px 0 !important;
}

.link-title-container h5 {
	color: #fff;
	font-weight: 600;
}

.introduction-container {
	margin: 0 auto;
	height: 480px;
	padding: 10px;
}

.introduction-content {
	width: 100%;
	padding: 20px !important;
	background: rgba(235, 234, 234, 0.28);
	border-radius: 1rem;
	border: 0;
	overflow: hidden;
	box-shadow: 0px 0px 6px -1px rgba(138, 56, 126, 0.53);
	height: 680px;
}



.count-container {
	gap: 1rem;
}

.introduction-container hr {
	border: 1px solid black !important;
	height: 2px;
	background: #000 !important;
	z-index: 100;
	color: #000 !important;
	margin-bottom: 0px !important;
}

.count {
	width: 100%;
	height: 190px !important;
	padding: 13px 17px 10px 17px;
	color: #fff;
	border-radius: 10px;
	transition: all 0.7s;
}

.count .top-content {
	padding: 0 0 7px 0;
	border-bottom: 1px dashed #e1d8d8a6;
}

.count .top-content h5 {
	font-size: 18px;
	font-weight: 600;
}


.bottom-image-container {
	width: 70px;
	height: 70px;
	overflow: hidden;
	background: aliceblue;
	border-radius: 53px;
	padding: 10px;
	border: 1px solid #faf9f9;
}

.bottom-image-container img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.introduction-content h4 {
	font-size: 18px;
	font-weight: 600;
	color: #000;
	border-bottom: 1px dashed #8080805e;
	padding-bottom: 10px;
	text-transform: capitalize;
	padding-top: 0px;
	margin-bottom: 10px;
}





iframe .vp-center {
	display: flex;
	align-items: baseline !important;
	justify-content: center;
}

/* quick links new css */
.quick-links-div {
	background: rgba(235, 234, 234, 0.28);
	padding:20px;
	box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
	border-radius: 15px;
	margin-bottom: 10px;
	box-shadow: 0px 0px 6px -1px rgba(138, 56, 126, 0.53);
}

.main-title {
	font-size: 17px;
	border-bottom: 1px solid #80808042;
	padding-bottom: 10px;
	margin-bottom: 15px;
}

.quick-inner-box {
	display: flex;
	align-items: center;
	justify-content: space-between;
	border: 1px solid var(--color-primary);
	padding: 12px 14px !important;
	border-radius: 22px;
	height: 119px;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
	margin-bottom: 10px !important;
}


.quick-inner-box .link-name {
	height: 100%;
	padding-top: 10px;
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.quick-links-div .icon {
	width: 30px;
	height: 30px;
	line-height: 40px;
	margin-left: 25px;
	color: #272727;
}

.quick-links-div .icon i {
	font-size: 15px;
}

.link-name .title {
	font-size: 14px;
	margin: 0;
	color: #272727;
	font-weight: 700;
}

.quick-links-div .main-title {
	font-size: 18px;
	font-weight: 600;
	color: #000;
	border-bottom: 1px dashed #8080805e;
	text-transform: capitalize;
	padding: 10px 0px;
	margin-bottom: 10px;
}

.qlc-rb {
	width: 80px;
	height: 80px;
	border-radius: 50%;
	background-color: red;
	display: flex;
	justify-content: center;
	align-items: center;
}

.qlc-link-container {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	background-color: green;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;
	font-weight: 700;
}

.quick-inner-box.link1 {
	box-shadow: rgba(125, 142, 220, 0.3) 0px 1px 8px;
	overflow: hidden;
	position: relative;
	transition: all 0.3s ease-in;
}

.quick-inner-box.link1:hover {
	box-shadow: rgba(125, 142, 220, 0.5) 0px 2px 10px;
}

.quick-inner-box.link1::before {
	content: "";
	position: absolute;
	left: -70px;
	height: 118px;
	width: 134px;
	background-color: #c0cff7;
	z-index: -1;
	border-radius: 50%;
	bottom: -61px;
}

.quick-inner-box.link2 {
	box-shadow: rgba(14, 189, 212, 0.3) 0px 1px 8px;
	position: relative;
	overflow: hidden;
	transition: all 0.3s ease-in;
}

.quick-inner-box.link2:hover {
	box-shadow: rgba(14, 189, 212, 0.5) 0px 1px 10px;
}

.quick-inner-box.link2::before {
	content: "";
	position: absolute;
	left: -70px;
	height: 118px;
	width: 134px;
	background-color: rgba(14, 189, 212, 0.4);
	z-index: -1;
	border-radius: 50%;
	bottom: -61px;
}

.quick-inner-box.link3 {
	box-shadow: rgba(247, 190, 62, 0.3) 0px 1px 8px;
	position: relative;
	overflow: hidden;
	transition: all 0.3s ease-in;
}

.quick-inner-box.link3:hover {
	box-shadow: rgba(247, 190, 62, 0.5) 0px 2px 10px;
}

.quick-inner-box.link3::before {
	content: "";
	position: absolute;
	left: -70px;
	height: 118px;
	width: 134px;
	background-color: rgba(247, 190, 62, 0.3);
	z-index: -1;
	border-radius: 50%;
	bottom: -61px;
}

.quick-inner-box.link4 {
	box-shadow: rgba(226, 92, 150, 0.4) 0px 1px 8px;
	position: relative;
	overflow: hidden;
	transition: all 0.3s ease-in;
}

.quick-inner-box.link4:hover {
	box-shadow: rgba(226, 92, 150, 0.5) 0px 2px 10px;
}

.quick-inner-box.link4::before {
	content: "";
	position: absolute;
	left: -70px;
	height: 118px;
	width: 134px;
	background-color: rgba(226, 92, 150, 0.3);
	z-index: -1;
	border-radius: 50%;
	bottom: -61px;
}

.qlc-rb.rb1 {
	background: radial-gradient(circle, rgba(238, 239, 249, 1) 84%, rgba(207, 211, 243, 1) 99%, rgba(202, 207, 242, 1) 100%);
	box-shadow: rgba(125, 142, 220, 0.2) 0px 5px 15px;
	transition: all 0.3s ease-in;
}

.qlc-rb.rb1:hover {
	box-shadow: rgba(125, 142, 220, 0.5) 0px 5px 15px;
}

.qlc-rb.rb1 .qlc-link-container {
	background: radial-gradient(circle, rgba(125, 142, 220, 0.9331933456976541) 36%, rgba(118, 134, 217, 1) 87%);
}

.qlc-rb.rb2 {
	background: radial-gradient(circle, rgba(215, 246, 249, 1) 36%, rgba(229, 249, 251, 1) 87%);
	box-shadow: rgba(14, 189, 212, 0.2) 0px 5px 15px;
	transition: all 0.3s ease-in;
}

.qlc-rb.rb2:hover {
	box-shadow: rgba(14, 189, 212, 0.5) 0px 5px 15px;
}

.qlc-rb.rb2 .qlc-link-container {
	background: radial-gradient(circle, rgba(14, 189, 212, 1) 50%, rgba(50, 172, 188, 1) 65%);

}

.qlc-rb.rb3 {
	background: radial-gradient(circle, rgba(255, 243, 219, 1) 74%, rgba(244, 218, 157, 1) 97%);
	box-shadow: rgba(247, 190, 62, 0.2) 0px 5px 15px;
	transition: all 0.3s ease-in;
}

.qlc-rb.rb3:hover {
	box-shadow: rgba(247, 190, 62, 0.5) 0px 5px 15px;

}

.qlc-rb.rb3 .qlc-link-container {
	background: radial-gradient(circle, rgba(247, 190, 62, 1) 37%, rgba(190, 144, 42, 1) 100%);

}

.qlc-rb.rb4 {
	background: radial-gradient(circle, rgba(249, 226, 236, 1) 74%, rgba(231, 171, 196, 1) 100%);
	box-shadow: rgba(226, 92, 150, 0.2) 0px 5px 15px;
	transition: all 0.3s ease-in;
}

.qlc-rb.rb4:hover {
	box-shadow: rgba(226, 92, 150, 0.5) 0px 5px 15px;
}

.qlc-rb.rb4 .qlc-link-container {
	background: radial-gradient(circle, rgba(226, 92, 150, 1) 40%, rgba(226, 92, 150, 1) 51%, rgba(207, 70, 129, 1) 76%, rgba(198, 60, 120, 1) 84%);
}

.quick-inner-box:hover .icon {
	animation: rotateBoth 0.8s ease-in;
}

@keyframes rotateBoth {
	0% {
		transform: rotate(0deg);
		transform: scale(1.1);
	}

	50% {
		transform: rotate(7deg);
	}

	100% {
		transform: rotate(-7deg);
	}
}

.assist-button-container {
	margin-top: 30px;
}

@media screen and (max-width: 1200px) {
	.welcome-container .welcome-content .user-content .user-image-container {
		width: 55px;
		height: 55px;
	}

	.welcome-container .welcome-content .welcome-text-container {
		padding: 15px 0px;
	}

	.welcome-container .welcome-content .welcome-text-container p {
		padding: 0px 10px;
	}

	.welcome-container .welcome-content .welcome-text-container h1 {
		font-size: 17px;
		font-weight: 700;
	}


	.qlc-rb {
		width: 70px;
		height: 70px;
	}

	.qlc-link-container {
		width: 40px;
		height: 40px;
	}

	.quick-inner-box {
		padding: 12px 10px !important;
	}

	.quick-links-div .icon {
		width: 25px;
		height: 25px;
	}

	.link-name .title {
		font-size: 14px;
	}

	.assist-content h3 {
		font-size: 24px;
		font-weight: 600;
	}
}

@media screen and (max-width: 1024px) {
	.welcome-container .welcome-content {
		padding: 15px 15px !important;
	}

	.welcome-container .welcome-content .user-content {
		padding: 0px;
	}

	.welcome-container .welcome-content .welcome-text-container {
		padding: 8px 0;
	}

	.welcome-container .welcome-content .welcome-text-container h1 {
		font-size: 14px;
	}

	.welcome-container .welcome-content .welcome-text-container p {
		line-height: 19px;
	}

	.quick-links-div .icon {
		margin-left: 11px;
	}

	.qlc-rb {
		width: 45px;
		height: 45px;
	}

	.qlc-link-container {
		width: 25px;
		height: 25px;
		font-size: 14px;
	}

	.assist-content h3 {
		font-size: 20px;
	}

	.assist-content p {
		font-size: 13px;
		line-height: 18px;
	}

	.welcome-container .welcome-content button {
		font-size: 12px;
	}

	.assist-content button {
		padding: 3px 15px !important;
		font-size: 13px;
	}

}

@media screen and (max-width: 991px) {
	.welcome-container .welcome-content {
		text-align: left;
		margin-bottom: 15px;
	}

	.welcome-container .welcome-content .welcome-text-container {
		padding: 15px 34px !important;
		text-align: left;
	}

	.welcome-container .welcome-content .welcome-text-container p {
		font-size: 15px;
	}

	.welcome-container .welcome-content .welcome-text-container h1 {
		font-size: 21px;
	}

	.welcome-container .welcome-content button {
		padding: 9px 21px !important;
	}

	.welcome-container .welcome-content .welcome-text-container p {
		padding: 0;

	}

	.qlc-rb {
		width: 65px;
		height: 65px;
	}

	.qlc-link-container {
		width: 45px;
		height: 45px;
		font-size: 16px;
	}

	.introduction-content {
		margin-top: -40px;
	}

	.main .sidebar-wrapper .sidebar {
		height: 100%;
	}

	.assist-content h3 {
		font-size: 29px;
	}

	.assist-content {
		padding: 30px 30px !important;
	}

	.assist-content p {
		font-size: 15px;
		width: 86%;
		margin-bottom: 10px;
	}

	.assist-content button {
		padding: 6px 19px !important;
	}

	.assist-button-container {
		margin-top: 10px;
	}

	.assist-container::before {
		top: 72px;
		right: -19px;
	}
}

@media screen and (max-width: 768px) {
	.child {
		padding: 5px;
	}

	.welcome-container .welcome-content .user-content h3 {
		font-size: 19px;
	}

	.welcome-container .welcome-content .welcome-text-container h1 {
		font-size: 18px;
	}

	.welcome-container .welcome-content .welcome-text-container p {
		font-size: 14px;
	}

	.assist-content h3 {
		font-size: 24px;
	}

	.assist-content p {
		font-size: 14px;
	}

	.vp-center {
		align-items: start !important;
	}

	.introduction-content .embed-responsive iframe {
		height: 100%;
	}

	.introduction-content {
		height: 100%;
	}

}

@media screen and (max-width: 360px) {
	.welcome-container .welcome-content .welcome-text-container {
		padding: 15px 7px !important;
	}

	.main .content-wrapper .right-container {
		padding: 90px 10px 10px 10px;
	}

	.assist-content h3 {
		font-size: 19px;
	}

	.assist-content p {
		font-size: 13px;
	}

	.assist-container::before {
		top: 149px;
		right: -24px;
	}

	.welcome-container .welcome-content .welcome-text-container {
		text-align: center;
	}

}