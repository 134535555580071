.main .sidebar-wrapper .sidebar {
	position: fixed;
	top: 70px;
	left: 0px;
	overflow-y: scroll !important;
	overflow-x: hidden;
	scrollbar-width: none;
	scrollbar-color: transparent transparent;
	box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.1);
	z-index: 1;
	background-image: url("../../assests/images/sidebar/sidebar4.jpg");
	background-size: cover;
	background-repeat: no-repeat;
	background-position: top center;
	position: relative;
	min-height: 0;
	max-height: 100vh;
}

.main .sidebar-wrapper .sidebar .title {
	padding: 10px 5px !important;
}

.main .sidebar-wrapper .sidebar ul li {
	list-style: none;
	margin-bottom: 3px;
}

.main .sidebar-wrapper .sidebar ul li button {
	font-family: "Open Sans", sans-serif !important;
	color: #5e5d72 !important;
	border-radius: 10px !important;
	padding: 12px 15px !important;
	font-weight: 600 !important;
	font-size: 14px;
	transition: all 0.3s ease-in !important;
}

.main .sidebar-wrapper .sidebar ul li button:hover {
	background-color: #75126a40;
}

.main .sidebar-wrapper .sidebar ul li button.active {
	background-color: #75126a40;
	margin-top: 2px;
	margin-bottom: 2px;
}

.main .sidebar-wrapper .sidebar ul li button.active svg {
	color: #75126a !important;
}

.main .sidebar-wrapper .sidebar ul li button.active .arrow {
	transform: rotate(90deg);
}

.main .sidebar-wrapper .sidebar ul li button .icon {
	width: 20px;
	height: 20px;
	margin-right: 10px;
}

.main .sidebar-wrapper .sidebar ul li button a {
	font-family: "Open Sans", sans-serif !important;
	text-decoration: none;
	color: #5e5d72 !important;
	border-radius: 0px !important;
	font-weight: 600 !important;
	font-size: 14px;
	transition: all 0.3s ease-in !important;
}

.main .sidebar-wrapper .sidebar ul li button .icon svg {
	color: #000;
	opacity: 0.7;
	font-size: 22px;
}

.main .sidebar-wrapper .sidebar ul li button .arrow {
	width: 25px;
	height: 25px;
	margin-left: auto;
	transition: all 0.3s ease-in-out;
}

/* .main .sidebar-wrapper .sidebar::-webkit-scrollbar{
    width: 3px;
    height: 50px !important;
  }
  .main .sidebar-wrapper .sidebar::-webkit-scrollbar-thumb{
  background: transparent !important;
  }
  .main .sidebar-wrapper:hover .sidebar::-webkit-scrollbar{
  background: #f1f1f1 !important;
  }
  .main .sidebar-wrapper:hover .sidebar::-webkit-scrollbar-track{
  background: #0858f7 !important;
  height: 50px !important;
  } */
.main .sidebar-wrapper .sidebar ul .subMenu {
	padding: 0px 45px !important;
	position: relative;
	transition: all 0.3s ease-in !important;
	/* height: 0px; */
}

.main .sidebar-wrapper .sidebar ul .subMenu::before {
	content: "";
	width: 1px;
	height: 95%;
	position: absolute;
	top: 0px;
	left: 25px;
	background-color: rgba(0, 0, 0, 0.2);
}

.main .sidebar-wrapper .sidebar ul .subMenu ul li {
	width: 100%;
	list-style: none;
}

.main .sidebar-wrapper .sidebar ul .subMenu li a {
	text-decoration: none;
	color: #5e5d72;
	display: block;
	padding: 6px 0px;
	font-size: 14px;
	font-weight: 500;
	transition: all 0.3s ease-in-out;
}

.main .sidebar-wrapper .sidebar ul .subMenu li a:hover {
	color: #0858f7 !important;
}

.main .sidebar-wrapper .sidebar ul .subMenu-wrapper {
	transition: all 0.3s ease-in !important;
	overflow: hidden !important;
}

.main .sidebar-wrapper .sidebar ul .subMenu-wrapper.close {
	height: 0px !important;
}

.main .sidebar-wrapper .sidebar ul .subMenu-wrapper.close .subMenu {
	opacity: 0 !important;
}

.main .sidebar-wrapper .sidebar ul .subMenu-wrapper.open {
	height: auto !important;
	overflow: inherit !important;
	padding: 5px 0;
}

.main .sidebar-wrapper .sidebar ul .subMenu-wrapper.open .subMenu {
	opacity: 1 !important;
	height: auto;
}

.logout-wrapper {
	padding: 0px 25px;
	margin-bottom: 40px;
}

.logout-wrapper .logout-box {
	width: 100%;
	height: 100px;
	padding: 25px;
	background-color: #bcd2fd;
	border-radius: 10px;
	position: relative;
	overflow: hidden;
}

.logout-wrapper .logout-box ::before {
	content: '';
	position: absolute;
	width: 20px;
	height: 20px;
	background-color: #a701a9;
	border-radius: 100%;
	top: 12px;
	left: -38px;
	opacity: 0.5;
}

.logout-wrapper .logout-box ::after {
	content: '';
	position: absolute;
	width: 80px;
	height: 80px;
	background-color: #a701a9;
	border-radius: 100%;
	top: -64px;
	right: -89px;
	opacity: 0.5;
}

.logout-wrapper .logout-box {
	width: 160px;
	height: 60px;
	padding: 25px;
	background-color: #df00a954;
	border-radius: 10px;
	position: relative;
}

.logout-wrapper .logout-box button {
	font-size: 14px;
	font-weight: 600;

}

.logout-wrapper .logout-box svg {
	margin-right: 5px;
	font-size: 18px;
}

@media screen and (max-width: 991px) {
	.logout-wrapper {
		display: none;
	}

	.main .sidebar-wrapper .sidebar .title {
		padding: 10px 5px 30px 5px !important;
	}

	.main .sidebar-wrapper .sidebar {
		height: 100%;
	}
}