.header {
    width: 100%;
    height: 70px;
    background-color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
  }

  .logo img {
    /* height: 45px; */
    width: auto;
    max-width: 195px;
  }

  @media screen and (max-width: 1200px) {
    .logo img {
      width: auto;
      max-width: 210px;
    }
  }
  @media screen and (max-width: 1024px) {
    .logo img {
      width: auto;
      max-width: 190px;
    }
  }
  header button.rounded-circle {
    min-width: 40px !important;
    width: 40px !important;
    height: 40px !important;
    background-color: #dfe4ef !important;
    color: #0858f7 !important;
    font-size: 18px !important;
    transition: all 0.2s ease-in-out;
  }
  header button.rounded-circle svg{
    color: #292929 !important;
    font-size: 18px !important;
    transition: all 0.2s ease-in-out;
  }
  header button.rounded-circle svg:hover {
      color: #0858f7 !important;
  }
  
  header .part2 {
    padding-left: 2.5rem;
  }
  
  header .header-description .header-title {
    font-weight: 700;
    color: #292929;
  }
  .myAcc{
      text-align: left;
      color: #0858f7 !important;
      text-transform: capitalize !important;
  }
  .myAcc .userImg {
    width: 40px;
    height: 40px;
    border: 1px solid #0858f7;
    border-radius: 100%;
    padding: 2px;
  }
  
  .myAcc .userImg .rounded-circle {
    width: 100%;
    height: 100%;
    overflow: hidden;
    cursor: pointer;
    position: relative;
  }
  .myAcc .userImg .rounded-circle img {
    width: 100%;
    height: 100%;
    object-fit: cover !important;
  }
  
  .myAcc .userInfo{
      padding-left: 10px;
  }
  .myAcc .userInfo h4{
      font-size: 15px;
      font-weight: 600;
      color: #292929;
  }
  .myAcc .userInfo p{
      font-size: 12px;
      opacity: 0.7;
      color: #000;
  }
  
  
  #account-menu li{
    font-size: 12px;
    padding: 6px 14px !important;
    margin-bottom: 5px;
    font-weight: 600;
    color: #464242;
  }

  .MuiListItemIcon-root {
    min-width: 28px !important;
  }

  /* .MuiButtonBase-root:hover{
    background-color: #ece1eb4f !important;
  } */

  .MuiPaper-root{
    padding: 1px 8px !important;
  }
  .logout-btn{
    padding: 0px !important;
    text-align: left !important;
    justify-content: flex-start !important;
  }

  .logout-btn li{
    width: 100% !important;
  }

  #account-menu li svg{
      font-size: 20px;
  }
  .user-profile{
    text-decoration: none;
    color: #000;
    font-size: 14px;
  }

  .logout{
    color: rgb(178, 9, 9) !important;
  }

  
  .header-right-area {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 50px;
    width: 100%;
  }

  .right-inner-content{
    display: flex;
    align-items: center;
  }

  .header-description p{
    font-size: 13px;
  }

  .logo-div{
    width: 255px;
  }

   .logo-favicon img{
    display: none;
 }

  
  @media screen and (max-width: 1024px){
    .header-right-area {
      margin-left: 50px;
      width: 100%;
      margin-right: 0px;
    }
}
@media screen and (max-width:991px){
  .right-inner-content{
   margin-right: 15px;
  }
  .header-right-area {
    margin-left: 40px;
   
  }
}
@media screen and (max-width: 768px) {
  .header-right-area {
    margin-left: 30px;
    width: 100%;
    margin-right:0px;
  }
}
@media screen and (max-width: 576px){
  .header-right-area {
    margin-left: 0;
    /* width: 68%; */
  }

  .header{
    padding: 0 10px;
  }

  .logo-div{
    width: 85px;
  }

  .logo-div .logo{
    display: none;
  }

 .logo-favicon img{
      display: block;
      width:35px;
  }
}
  
@media screen and (max-width: 360px) {
  header .header-description .header-title {
    font-weight: 700;
    color: #000;
    font-size: 17px;
  }
  .right-inner-content {
    margin-right: 0;
  }
  .header-right-area {
    margin-left: 0;
    width: 100%;
  }

  .logo-div {
    width:80px;
  }
 
}