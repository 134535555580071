body {
	background-color: #fff;
}

.login-box {
	height: 100vh;
}

/* -------------------------------------------left login container---------------------------------- */
.left-login-container {
	background-color: #f2f2f2;
	flex: 0 0 auto;
	width: 40%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.left-login-container .login-head .login-logo {
	width: 250px;
	height: auto;
}

.left-login-container .login-head .login-logo img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.left-login-container .login-head .title {
	font-size: 31px;
	font-weight: 700;
	margin-top: 30px;
	color: #2f2f35;
	margin-bottom: 10px;

}

.login-box-wrapper .login-box .sub-title {
	margin-bottom: 20px;
}

.login-box-wrapper .login-box .forgot-content {
	padding: 20px 20px 20px 0;
	margin-bottom: 46px;
}

.login-box-wrapper .login-box .forgot-link {
	margin-bottom: 30px;
	margin-top: 15px;
	text-align: start;
	transition: all 0.3s linear;
}

.login-box-wrapper .login-box .forgot-link a {
	color: var(--primary-color);
}

.login-box-wrapper .login-box .forgot-link a:hover {
	color: var(--secondary-color);
}

.login-box-wrapper .login-box .form-group {
	margin-bottom: 20px;
}

.login-box-wrapper .login-box .form-group label {
	margin-bottom: 5px;
	font-weight: 700;
}

.login-box-wrapper .login-box .login-button.btn {
	padding: 12px 20px;
	font-size: 15px;
	text-transform: uppercase;
	font-weight: 700;
	letter-spacing: 1.1px;
	background: linear-gradient(90deg, #8955c1, #75126a);
	color: #fff;
	margin-bottom: 15px;
	border-radius: 30px;
	cursor: pointer;
	transition: all 0.3s ease-in-out;
}

.login-box-wrapper .login-box .login-button.btn:hover {
	background: linear-gradient(90deg, #75126a, #8955c1);
}

.left-login-container .form-control {
	border: 1px solid rgb(100, 93, 99);
	border-radius: 6px;
	padding: 1.2rem 0.75rem;
	height: 50px;
}

.left-login-container .form-control:focus {
	border: 1px solid var(--secondary-color);
	border-radius: 8px;
	box-shadow: none;
	border-color: var(--secondary-color);
	background-color: transparent;
}

.left-login-container label {
	font-size: 17px;
}

.left-login-container .login-input-icon {
	top: 11px;
	right: 20px;
}

.left-login-container .login-input-icon svg {
	font-size: 20px;
	color: var(--primary-color);
}

.forgot-link a {
	font-weight: 600;
}

.login-box-wrapper .login-box .login-button.forgot-button {
	padding: 6px 20px;
	font-size: 15px;
	text-transform: uppercase;
	font-weight: 700;
	letter-spacing: 1.1px;
	transition: all 0.3s ease-in-out;
}

/* -------------------------------------------right login container---------------------------------- */
.right-login-container {
	height: 100%;
	background: linear-gradient(180deg, #bba3d5, #75126a);
	overflow: hidden;
	flex: 0 0 auto;
	width: 60%;
	padding: 40px 0 0 90px;
	overflow: hidden;
}

.rlc-top {
	width: 70%;
	text-align: left;
}

.rlc-top h1 {
	font-weight: 700;
	color: #ffffff;
}

.rlc-top p {
	font-weight: 500;
	color: #ffffff;
}

.rlc-bottom {
	margin-top: 60px;
	border-radius: 20px 0 0 0;
	overflow: hidden;
	width: 100%;
}

.rlc-bottom img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

/*---------------------------------------------------------media queries-------------------------------------------------------------------- */
@media only screen and (max-width: 1200px) {
	.login-box-wrapper .login-box .title {
		font-size: 26px;
	}

	.login-box-wrapper .login-box .sub-title {
		font-size: 14px;
	}

	.left-login-container .form-control {
		height: 39px;
	}

	.login-box-wrapper .login-box .login-button.btn {
		padding: 10px 20px;
		font-size: 15px;
	}

	.login-box-wrapper .login-box .forgot-link {
		font-size: 15px;
	}

	.login-box-wrapper .login-box .forgot-link a {
		font-size: 15px;
	}

	.login-box-wrapper .login-box .form-control::placeholder {
		font-size: 14px;
	}

}

@media only screen and (max-width: 1024px) {
	.left-login-container {
		width: 45%;
	}

	.right-login-container {
		width: 55%
	}

	.left-login-container .login-head .login-logo {
		width: 220px;
	}

	.login-box-wrapper .login-box .title {
		font-size: 25px;
	}

	.left-login-container label {
		font-size: 15px;
	}

	.login-box-wrapper .login-box .forgot-link {
		font-size: 14px;
	}

	.login-box-wrapper .login-box .forgot-link a {
		font-size: 14px;
	}

	.rlc-top h1 {
		font-size: 30px;
	}

	.rlc-top p {
		font-size: 15px;
	}
}

@media only screen and (max-width: 991px) {

	.left-login-container .login-head,
	.login-form {
		width: 85%;
		margin: auto;
	}

	.login-box-wrapper .login-box .title {
		font-size: 24px;
	}

	.rlc-top h1 {
		font-size: 29px;
	}
}

@media only screen and (max-width: 768px) {
	.left-login-container {
		padding: 0;
	}

	.left-login-container> :first-child {
		padding: 0;
	}

	.left-login-container> :first-child> :first-child {
		padding: 0;
	}

	.left-login-container .login-head .login-logo {
		width: 190px;
	}

	.login-box-wrapper .login-box .forgot-link {
		font-size: 13px;
	}

	.login-box-wrapper .login-box .forgot-link a {
		font-size: 13px;
	}

	.login-box-wrapper .login-box .title {
		font-size: 21px;
	}

	.login-box-wrapper .login-box .sub-title {
		font-size: 13px;
	}

	.login-box-wrapper .login-box .login-button.btn {
		padding: 6px 20px;
	}

	.rlc-top {
		width: 85%;
	}

	.rlc-top h1 {
		font-size: 25px;
	}

	.rlc-top p {
		font-size: 14px;
	}

	.rlc-bottom {
		margin-top: 50px;
		border-radius: 20px 0 0 0;
		overflow: hidden;
		width: 800px;
		height: 500px;
	}

	.rlc-bottom img {
		object-fit: cover;
		width: 100%;
		height: 100%;
		object-position: left;
	}
}



@media only screen and (max-width: 576px) {
	.main-login-container {
		padding: 0;
	}

	.login-box {
		margin: auto;
		background: linear-gradient(180deg, #bba3d5, #75126a);
		padding: 0;
	}

	.login-box>:first-child {
		align-items: center;
	}

	.left-login-container {
		width: 80%;
		height: 500px;
		border-radius: 20px;
		backdrop-filter: blur(20px);
		background-color: #f9f5f59e;
	}

	.right-login-container {
		display: none;
	}

}

@media only screen and (max-width: 400px) {
	.left-login-container {
		width: 90%;
	}
}