.nav-link{
    cursor: pointer;
}
.training-box{
    background-color: #fff;
    margin-bottom: 20px;
    border-radius: 12px;
    transition: all 0.5s;
    box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.15);
}

.training-box .media{
    height: 215px;
    width: 100%;
    padding: 15px 15px 0 15px;
    margin-bottom: 0;
}

.training-box .content{
    padding: 10px 15px 20px 15px;
     text-align: center;
}

.training-box .content .title {
    margin-bottom: 20px;
    font-size: 15px;
    font-weight: 600;
}
.training-box:hover{
    cursor: pointer;
    box-shadow: 0px 2px 5px 1px rgba(214, 0, 170, 0.39);
}
.video-container.demo-video{
    background: #64435d0f;
    padding: 0 12px;
    border-radius: 10px;
    border: 1px solid #c950b13b;
}