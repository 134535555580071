
body {
  overflow-y: scroll; /* Enable vertical scrolling */
  scrollbar-width: none; /* Firefox: hide scrollbar */
  -ms-overflow-style: none; /* Internet Explorer 10+ and Edge: hide scrollbar */
}

/* Chrome, Safari, and other WebKit-based browsers */
body::-webkit-scrollbar {
  display: none !important; /* Hide scrollbar */
}

 /* width */
 ::-webkit-scrollbar {
  width: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
} 

.card{
	position: relative;
	display: flex;
	flex-direction: column;
	min-width: 0;
	word-wrap: break-word;
	background-color: #fff;
	background-clip: border-box;
	border: 0px  !important;
	border-radius: 15px !important;
	padding: 10px !important;
	box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.15);
	overflow: hidden;
}

.transition{
	transition: all 0.3s ease-in-out;
}

.list-empty-box .icon {
	font-size: 24px;
	background: transparent !important;
	display: inline-block;
	width: 50px;
	height: 50px;
	border-radius: 9px;
	line-height: 25px !important;
	margin-bottom: 15px;
	border: 1px solid #00000091;
	padding: 8px;
  }

.list-empty-box .icon img{
	width: 100%;
	height: 100%;
	object-fit: contain;
}