@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: "Open Sans", sans-serif;
}

:root {
	--primary-color: rgb(117, 18, 106) !important;
	--secondary-color: rgba(183, 19, 150, 0.74);
	--hover-color: rgb(214, 0, 170);
	--text-color: #131313;
}

body {
	font-family: "Open Sans", sans-serif !important;
	background: #fff !important;
	overflow-x: hidden;
	scrollbar-width: none;
}

/* buttons  style start from here*/

.btn-primary {
	background-color: var(--primary-color) !important;
	border-color: var(--primary-color) !important;
	color: #fff;
}

.btn-primary:hover {
	background-color: var(--hover-color) !important;
	border-color: var(--hover-color) !important;
	color: #fff !important;
}

.main .sidebar-wrapper {
	width: 280px;
	transition: all 0.3s ease-in-out;
}

@media screen and (max-width: 991px) {
	.main .sidebar-wrapper {
		width: 65px;
	}
}

.main .content-wrapper {
	width: 100%;
	overflow: hidden;
}

@media screen and (max-width: 991px) {
	.main .content-wrapper.pageOpen {
		margin-left: 195px;
	}

	.main .content-wrapper.pageClose {
		margin-left: 2rem;
	}
}

@media screen and (max-width: 360px) {
	.main .content-wrapper.pageOpen {
		margin-left: 20px;
	}

	.main .content-wrapper.pageClose {
		margin-left: 1rem;
	}
}

.right-container {
	width: 100%;
	height: 100%;
	padding: 90px 50px 10px 50px;
	/* background: rgb(245, 232, 226);
	background: linear-gradient(
		156deg,
		rgba(245, 232, 226, 1) 17%,
		rgba(222, 230, 236, 1) 68%,
		rgba(205, 221, 232, 1) 100%
	); */
}

.form-control {
	display: block;
	width: 100%;
	height: 35px;
	padding: 0.375rem 0.75rem;
	font-size: 13px;
	font-weight: 400;
	line-height: 1.5;
	color: #6e707e;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #d1d3e2;
	border-radius: 9px;
	outline: none;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control:focus {
	font-size: 14px;
	color: #000;
	background-color: rgb(247, 247, 242) !important;
	/* border: none; */
	outline: none !important;
	box-shadow: 0 0 0 1px rgba(13, 110, 253, 0.25) !important;
}

.form-select:focus {
	color: #000;
	background-color: rgb(247, 247, 242) !important;
	/* border: none; */
	outline: none !important;
	box-shadow: 0 0 0 2px rgba(13, 110, 253, 0.25) !important;
}

.truncated-two-lines {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	line-clamp: 2;
	-webkit-line-clamp: 2;
	/* Limit to 2 lines */
	overflow: hidden;
	text-overflow: ellipsis;
	/* Add ellipsis (...) */
}

.page-title-box {
	position: relative;
	padding: 0 30px;
	margin: 0 -30px 0 -30px;
	padding-bottom: 10px;
	margin-bottom: 20px;
	margin-top: 0px;
}

.page-title-box .page-title {
	font-size: 20px;
	margin: 0;
	line-height: 17px;
	font-weight: 700;
}

.card-header {
	padding: 0.75rem 1.5rem;
	margin-bottom: 0;
	background-color: #f9f9f9;
	border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.card-header:first-child {
	border-radius: 15px 15px 0px 0px;
}

.profile-heading {
	margin-bottom: 0;
	padding: 10px 0 10px 0;
}

.card-top-header .profile-heading {
	padding: 10px 10px 15px 10px;
	margin-bottom: 0;
	border-bottom: 1px dashed #808080bf;
}

.description-text2 {
	font-size: 17px;
	color: #131111fc;
	font-weight: 500;
	line-height: 1.7;
}

label {
	color: #1a1a1adb;
	font-weight: 500 !important;
	margin-right: 15px;
	font-size: 14px;
	padding-left: 5px;
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
	padding-left: 1.5rem;
	padding-right: 1.5rem;
}

a {
	text-decoration: none !important;
}

.form-card {
	position: relative;
	display: flex;
	flex-direction: column;
	min-width: 0;
	word-wrap: break-word;
	background-color: #fff;
	background-clip: border-box;
	border: 0px;
	border-radius: 15px;
	margin-bottom: 30px;
	box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.15);
}

.form-body {
	min-height: 1px;
	padding: 1.25rem;
}

.form-body.team-form-body {
	min-height: 83vh;
	padding: 1.25rem;
}

.head-part {
	border-bottom: 1px dashed #80808057;
	padding-bottom: 5px;
	margin-bottom: 25px;
	margin: 0px 5px 25px 0px;
}

.head-part .title {
	font-size: 19px;
	font-weight: 600;
}

@media screen and (max-width: 360px) {
	.head-part {
		flex-wrap: wrap;
	}

	input.custom-form-control {
		width: 100%;
	}

	.right-container .company-search-bar input {
		width: 100% !important;
	}
}

@media screen and (max-width: 1024px) {
	.head-part .title {
		font-size: 17px;
		font-weight: 600;
	}
}

@media screen and (max-width: 360px) {
	.head-part .title {
		font-size: 15px;
		font-weight: 600;
	}
}

.form-select {
	padding: 8px 10px !important;
	border-radius: 9px !important;
	font-size: 15px !important;
}

.form-control.custom-form-control {
	height: 40px;
	padding: 10px 10px !important;
	font-size: 13px !important;
	border-radius: 9px;
}

.form-control.custom-form-control::placeholder {
	font-size: 15px !important;
}

.form-control.custom-form-control:focus {
	border: none;
	background: #fff !important;
	outline: none !important;
	box-shadow: 0 0 0 1px rgba(34, 36, 40, 0.25) !important;
}

.form-control.form-radius {
	height: 35px;
	font-size: 13px !important;
	border-radius: 9px;
}

.line-height {
	line-height: 0;
}

/* list style from here */

.right-container .site-card {
	width: 100%;
	border-radius: 12px;
	background-color: #fff !important;
	border: 0px;
	box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.15);
	position: relative;
}

.right-container .company-search-bar {
	margin-bottom: 2rem !important;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.right-container .company-search-bar label {
	/* font-size: 1.5rem; */
	font-weight: 700;
	opacity: 0.9;
}

.right-container .company-search-bar input {
	width: 60%;
}

.site-card .card-title {
	font-size: 17px;
	font-weight: 600;
	/* border-bottom: 1px solid #80808054; */
	padding-bottom: 5px;
	padding-top: 5px;
}

.site-card .card-title,
.card-text {
	margin-bottom: 5px;
}

.right-container .site-card .site-card-image-container {
	width: 100%;
	height: 170px !important;
	overflow: hidden;
	border-radius: 12px 12px 0px 0px !important;
	background: #b7b8b921;
	padding: 15px 15px;
}

.right-container .site-card .site-card-image-container img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	background-color: #fff;
}

.site-card .comapny-category {
	font-size: 14px;
	font-weight: 600;
	color: #fff;
	background: rgba(153, 50, 132, 0.94);
	display: inline-block;
	padding: 3px 8px;
	border-radius: 3px;
	position: absolute;
	top: 4px;
	right: 5px;
}

.site-card .company-description {
	font-size: 14px;
	font-weight: 500;
	opacity: 0.7;
	/* height: 60px; */
}

.site-card .company-creation-date {
	font-size: 14px;
	font-weight: 500;
	margin-top: 10px;
	margin-bottom: 10px;
}

.site-card .company-creation-date span {
	font-size: 14px;
	font-weight: 600;
	opacity: 0.9;
	color: #0a0ab7;
}

.site-card .compay-card-btn-container button {
	padding: 4px 7px !important;
	font-size: 14px;
	font-weight: 600;
	color: #fff;
	transition: all 0.3s ease-in-out;
	display: flex;
	justify-content: center;
	align-items: center;
}

.site-card .compay-card-btn-container button:hover {
	transform: translateY(-1px);
}

.site-card .compay-card-btn-container button svg {
	font-size: 14px;
	font-weight: 600;
	color: #f0eeee;
	margin-right: 5px;
}

.site-card .compay-card-btn-container {
	margin-top: 1rem;
}

.right-container .pagination-container ul li {
	margin-right: 5px !important;
}

.img-box {
	width: 300px;
	height: 150px !important;
	background: #eae3e347;
	padding: 10px;
	object-fit: contain;
	border-radius: 5px;
}

/* hired staff style */

.staff-member-image {
	background: aliceblue;
	width: 135px;
	height: 140px;
	border-radius: 100%;
	text-align: center;
	margin: auto;
	border: 1px solid var(--primary-color);
}

.staff-member-image img {
	width: 100%;
	height: 100%;
	border-radius: 100%;
	object-fit: cover;
}

.staff-div .name {
	font-size: 17px;
	font-weight: 700;
	text-align: center;
	color: var(--primary-color);
}

.staff-div .profile {
	font-size: 13px;
	font-weight: 600;
	color: #000;
	text-align: center;
}

.staff-div .description-text {
	color: #fff;
	font-size: 13px;
	line-height: 20px;
}

.staff-div {
	position: relative;
}

.hover-btn {
	position: absolute;
	bottom: 0px;
	left: 0%;
	opacity: 0;
	transition: all 0.7s;
	transition: all 0.7s;
	overflow: hidden;
	z-index: 1;
	text-align: center;
	padding: 10px 20px;
	/* height: 100%; */
	width: 100%;
}

.staff-div:hover .hover-btn {
	bottom: 20%;
	opacity: 1;
}

.staff-div .hover-effect::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	height: 0;
	width: 100%;
	transition: all 0.5s;
	background-color: rgba(19, 20, 21, 0.9);
	opacity: 1;
	z-index: 1;
}

.staff-div:hover .hover-effect::before {
	height: 100%;
	transition: all 0.7s;
}

.staff-div:hover {
	cursor: pointer;
}

@media screen and (max-width: 1200px) {
	.staff-div:hover .hover-btn {
		bottom: 15%;
		opacity: 1;
	}
}

@media screen and (max-width: 360px) {
	.staff-div:hover .hover-btn {
		opacity: 1;
		top: 5px;
		height: 100%;
	}

	.staff-div .description-text {
		color: #fff;
		font-size: 12px;
		line-height: 1.4 !important;
		margin-bottom: 10px;
	}
}

/* pagination */
.page-link {
	position: relative;
	display: block;
	padding: 0.5rem 0.75rem;
	margin-left: -1px;
	line-height: 1.25;
	margin-left: -1px;
	line-height: 1.25;
	color: #000 !important;
	background-color: #fff !important;
	border: 1px solid var(--secondary-color) !important;
}

.page-item.active .page-link {
	z-index: 3;
	color: #fff !important;
	background-color: var(--primary-color) !important;
	border-color: var(--secondary-color) !important;
	line-height: 21px;
}

.page-link:focus {
	box-shadow: none !important;
}

.btn {
	display: inline-block;
	font-weight: 500;
	color: #fff;
	text-align: center;
	vertical-align: middle;
	user-select: none;
	border: 1px solid transparent;
	padding: 5px 15px;
	font-size: 15px;
	line-height: 1.5;
	border-radius: 9px;
	transition: all 0.3s ease-in;
}

.des-text {
	border-bottom: 1px solid #80808054;
	padding-bottom: 5px;
	padding-top: 0;
	overflow: hidden;
}

.instuction-text {
	color: #bb1a1a;
	font-size: 12px;
	padding-left: 5px;
}

/* model style start from here */
.modal-content {
	background-color: var(--bs-modal-bg) !important;
	box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.15);
	border: 0px !important;
}

.modal-header {
	justify-content: space-between;
	padding: 10px 10px !important;
	border-bottom: 1px dashed #80808073 !important;
}

.modal-header h5 {
	font-size: 18px;
	font-weight: 600;
}

.modal-content .close {
	padding: 1px 8px;
	background: var(--secondary-color);
	border: 0px;
	border-radius: 4px;
	color: #fff;
}

/* sidebar-toogle */
@media (min-width: 360px) {
	.toogleOpen.sidebar {
		width: 245px;
	}

	.toggleClose.sidebar {
		width: 60px;
	}
}

.nav-item {
	margin-right: 5px;
}

.nav-pills .nav-link {
	background: rgba(181, 112, 161, 0.31);
	color: #000 !important;
	padding: 8px 40px;
	border: 1px solid var(--secondary-color);
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
	color: var(--bs-nav-pills-link-active-color);
	background-color: var(--secondary-color) !important;
	color: #fff !important;
}

@media screen and (max-width: 1200px) {
	.nav-pills .nav-link {
		padding: 8px 16px;
	}
}

@media screen and (max-width: 1024px) {
	.nav-pills .nav-link {
		padding: 10px 16px;
		font-size: 14px;
	}
}

@media screen and (max-width: 360px) {
	.nav-pills .nav-link {
		padding: 8px 10px;
		font-size: 14px;
		margin-bottom: 5px;
	}
}

.description-text {
	line-height: 1.7 !important;
	font-size: 15px;
}

/* badge */
.badge {
	display: inline-block;
	padding: 7px 10px;
	font-size: 12px;
	font-weight: 700;
	text-align: center;
	white-space: nowrap;
	vertical-align: baseline;
	border-radius: 5px;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.badge-success {
	color: #fff;
	background-color: #1cc88a;
}

/* table style from here */

.table-responsive {
	display: block;
	width: 100%;
	overflow-x: auto;
	-webkit-overflow-scrolling: touch;
	min-height: 245px;
}

.table-responsive>.table-bordered {
	border: 0;
}

.table {
	width: 100%;
	margin-bottom: 1rem;
	color: #fff;
	text-align: center;
}

.table td,
.table th {
	padding: 0.75rem;
	vertical-align: middle;
	border-top: 1px solid #e3e6f0;
}

.table thead th {
	vertical-align: bottom;
	border-bottom: 0px;
}

.table tbody+tbody {
	border-top: 2px solid #e3e6f0;
}

.table-sm td,
.table-sm th {
	padding: 0.3rem;
}

.table-bordered {
	border: 1px solid #e3e6f0;
}

.table-bordered td,
.table-bordered th {
	border: 1px solid #e3e6f0;
}

.table-bordered thead td,
.table-bordered thead th {
	border-bottom-width: 2px;
}

.table-borderless tbody+tbody,
.table-borderless td,
.table-borderless th,
.table-borderless thead th {
	border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
	background-color: #8456804a;
}

.table-striped tbody tr:nth-of-type(even) {
	background-color: #b8a2b60a;
}

.table-hover tbody tr:hover {
	color: #ffff;
	background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary>td,
.table-primary>th {
	background-color: #cdd8f6;
}

.table-primary tbody+tbody,
.table-primary td,
.table-primary th,
.table-primary thead th {
	border-color: #a3b6ee;
}

.table-hover .table-primary:hover {
	background-color: #b7c7f2;
}

.table-hover .table-primary:hover>td,
.table-hover .table-primary:hover>th {
	background-color: #b7c7f2;
}

.table-secondary,
.table-secondary>td,
.table-secondary>th {
	background-color: #dddde2;
}

.table-secondary tbody+tbody,
.table-secondary td,
.table-secondary th,
.table-secondary thead th {
	border-color: #c0c1c8;
}

.table-hover .table-secondary:hover {
	background-color: #cfcfd6;
}

.table-hover .table-secondary:hover>td,
.table-hover .table-secondary:hover>th {
	background-color: #cfcfd6;
}

.table-success,
.table-success>td,
.table-success>th {
	background-color: #bff0de;
}

.table-success tbody+tbody,
.table-success td,
.table-success th,
.table-success thead th {
	border-color: #89e2c2;
}

.table-hover .table-success:hover {
	background-color: #aaebd3;
}

.table-hover .table-success:hover>td,
.table-hover .table-success:hover>th {
	background-color: #aaebd3;
}

.table-info,
.table-info>td,
.table-info>th {
	background-color: #c7ebf1;
}

.table-info tbody+tbody,
.table-info td,
.table-info th,
.table-info thead th {
	border-color: #96dbe4;
}

.table-hover .table-info:hover {
	background-color: #b3e4ec;
}

.table-hover .table-info:hover>td,
.table-hover .table-info:hover>th {
	background-color: #b3e4ec;
}

.table-warning,
.table-warning>td,
.table-warning>th {
	background-color: #fceec9;
}

.table-warning tbody+tbody,
.table-warning td,
.table-warning th,
.table-warning thead th {
	border-color: #fadf9b;
}

.table-hover .table-warning:hover {
	background-color: #fbe6b1;
}

.table-hover .table-warning:hover>td,
.table-hover .table-warning:hover>th {
	background-color: #fbe6b1;
}

.table-danger,
.table-danger>td,
.table-danger>th {
	background-color: #f8ccc8;
}

.table-danger tbody+tbody,
.table-danger td,
.table-danger th,
.table-danger thead th {
	border-color: #f3a199;
}

.table-hover .table-danger:hover {
	background-color: #f5b7b1;
}

.table-hover .table-danger:hover>td,
.table-hover .table-danger:hover>th {
	background-color: #f5b7b1;
}

.table-light,
.table-light>td,
.table-light>th {
	background-color: #fdfdfe;
}

.table-light tbody+tbody,
.table-light td,
.table-light th,
.table-light thead th {
	border-color: #fbfcfd;
}

.table-hover .table-light:hover {
	background-color: #ececf6;
}

.table-hover .table-light:hover>td,
.table-hover .table-light:hover>th {
	background-color: #ececf6;
}

.table-dark,
.table-dark>td,
.table-dark>th {
	background-color: #d1d1d5;
}

.table-dark tbody+tbody,
.table-dark td,
.table-dark th,
.table-dark thead th {
	border-color: #a9aab1;
}

.table-hover .table-dark:hover {
	background-color: #c4c4c9;
}

.table-hover .table-dark:hover>td,
.table-hover .table-dark:hover>th {
	background-color: #c4c4c9;
}

.table-active,
.table-active>td,
.table-active>th {
	background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
	background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover>td,
.table-hover .table-active:hover>th {
	background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
	color: #fff;
	background-color: #5a5c69;
	border-color: #6c6e7e;
}

.table .thead-light th {
	color: #6e707e;
	background-color: #eaecf4;
	border-color: #e3e6f0;
}

.table-dark {
	color: #fff;
	background-color: #5a5c69;
}

.table-dark td,
.table-dark th,
.table-dark thead th {
	border-color: #6c6e7e;
}

.table tbody tr td {
	background-color: #8456804a !important;
	border: 0;
}

.table-dark.table-bordered {
	border: 0;
}

/* .table-dark.table-striped tbody tr:nth-of-type(odd) {
	background-color: rgba(255, 255, 255, 0.05);
} */

.table-dark.table-hover tbody tr:hover {
	color: #fff;
	background-color: rgba(255, 255, 255, 0.075);
}

table.dataTable {
	clear: both;
	max-width: none !important;
	border-collapse: separate !important;
	border: 1px solid #d2c6c678;
	padding: 5px 5px;
	color: #000;
	border-radius: 10px;
	margin-top: 40px;
	margin-bottom: 40px !important;
	text-align: center;
}

.table>thead:first-child>tr:first-child>th {
	color: #fff;
	background-color: rgb(132, 86, 128);
	font-size: 14px;
	line-height: 22px !important;
}

.table>thead>tr>th {
	border-top: 2px solid #fff;
	line-height: 30px;
	font-size: 15px;
	vertical-align: top;
}

.table>tbody>tr {
	line-height: 20px;
	font-weight: 500;
	vertical-align: middle;
}

table>tbody>tr>td {
	font-size: 14px;
}

@media (max-width: 575.98px) {
	.table-responsive-sm {
		display: block;
		width: 100%;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
	}

	.table-responsive-sm>.table-bordered {
		border: 0;
	}
}

@media (max-width: 767.98px) {
	.table-responsive-md {
		display: block;
		width: 100%;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
	}

	.table-responsive-md>.table-bordered {
		border: 0;
	}
}

@media (max-width: 991.98px) {
	.table-responsive-lg {
		display: block;
		width: 100%;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
	}

	.table-responsive-lg>.table-bordered {
		border: 0;
	}
}

@media (max-width: 1199.98px) {
	.table-responsive-xl {
		display: block;
		width: 100%;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
	}

	.table-responsive-xl>.table-bordered {
		border: 0;
	}
}

.btn-tool {
	background: #352c2c59 !important;
	color: #fff !important;
}

.dataTables_info {
	font-size: 14px;
	font-weight: 600;
}

table .dropdown-toggle::after {
	display: none;
}

.dropdown-item {
	font-size: 14px;
	font-weight: 600 !important;
	padding: 3px 8px !important;
}

.dropdown-menu {
	padding: 7px 4px !important;
	border: 0px !important;
	box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.15);
}

.f17 {
	font-size: 17px;
}

#formRange {
	width: 110px !important;
	margin: 0 10px;
}

#animation-bar-spinner {
	background: rgba(244, 244, 244, 0.95);
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	top: 0;
	z-index: 9998;
	align-items: center;
	justify-content: center;
}

.animation-spinner {
	left: 50%;
	top: 50%;
	position: absolute;
	z-index: 19 !important;
	transform: translate(-50%, -50%);
}

.animation-spinner img {
	width: 295px;
}

.form-control-style__control {
	border-radius: 9px !important;
	border-color: #dee2e6 !important;
}

.form-control-style__control .css-tj5bde-Svg {
	color: #0000007d;
}

.form-control-style__control:focus {
	border-color: #dee2e6 !important;
}

.form-control.upload-img {
	padding: 7px 6px 10px 8px !important;
}

.radius {
	border-radius: 9px !important;
}

.css-13cymwt-control {
	border-radius: 9px !important;
}

@media screen and (max-width: 360px) {
	.responsive-div {
		flex-wrap: wrap;
	}
}

@media screen and (max-width: 360px) {
	.site-card .company-creation-date span {
		font-size: 13px;
		font-weight: 700;
	}

	.site-card .company-creation-date {
		font-size: 11px;
		font-weight: 700;
	}

	.site-card .compay-card-btn-container button {
		font-size: 10px;
	}

	.site-card .comapny-category {
		font-size: 11px;
	}
}

/* react select styles */
.custom-select .css-1jqq78o-placeholder {
	color: #0000008a !important;
	font-size: 14px;
}

.custom-select .css-tj5bde-Svg {
	color: #0000007d;
}

.form-control-style__value-container .css-1jqq78o-placeholder {
	color: #0000008a !important;
	font-size: 14px;
}

.modal {
	background: #2f3235e3;
}

.empty-box {
	border-radius: 13px;
	padding: 20px 15px;
	box-shadow: 0px 0px 2px 1px rgba(159, 74, 150, 0.64);
	background: #a63d9a0a;
}

.messages-container .empty-box {
	border-radius: 13px;
	padding: 20px 15px;
	box-shadow: 0px 0px 2px 1px rgba(159, 74, 150, 0.64);
	background: #a63d9a0a;
	margin-top: 50px;
}

.list-empty-box {
	border-radius: 10px;
	padding: 15px 15px;
	text-align: center;
}

.list-empty-box .icon {
	font-size: 28px;
	background: #5908499c;
	display: inline-block;
	width: 45px;
	height: 45px;
	border-radius: 11px;
	line-height: 38px;
	margin-bottom: 15px;
}

.list-empty-box p {
	font-size: 18px;
	font-weight: 600;
}

@media screen and (max-width: 360px) {
	.messages-container .empty-box {
		margin-top: 5px;
	}

	.list-empty-box p {
		font-size: 14px;
		font-weight: 600;
	}

	.list-empty-box .icon {
		width: 45px;
		height: 45px;
	}
}

.alert-text {
	color: #b91515;
	font-size: 12px;
	padding-left: 5px;
}

.card-box-inner .open-ai-step {
	font-size: 20px;
	font-weight: 600;
}

.card-box-inner .s-title {
	font-size: 17px;
}

.swal2-title {
	font-size: 22px;
}

.Conversations-div {
	border: 1px solid rgb(240, 239, 239);
	border-radius: 11px;
	margin-top: 20px;
	margin-bottom: 20px;
	height: 385px;
	overflow: hidden;
}

.Conversations-header-div {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	border-top-left-radius: 11px;
	border-top-right-radius: 11px;
	background: #ced6dd6e;
	padding: 15px 15px;
}

.right-button-box {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.Conversations-div .messages-container {
	padding: 20px 20px;
	overflow-y: scroll !important;
	overflow-x: hidden;
	scrollbar-width: none;
	scrollbar-color: transparent transparent;
	height: 300px;
}

.message.user {
	max-width: 485px;
	min-width: 50px;
	margin-left: 68%;
	text-align: right;
}

.message.staff {
	width: 505px;
	margin-right: 90%;
	margin-top: 15px;
}

/* user -part */
.Conversations-div .message.user .chat {
	display: inline-block;
	background: #73456a36 !important;
	color: #333 !important;
	padding: 10px 15px;
	border-radius: 15px;
	min-width: 150px;
	max-width: 500px;
	text-align: left;
	font-size: 14px;
	font-weight: 500;
	line-height: 25px;
	margin-bottom: 7px;
}

.Conversations-div .message.user .tag {
	background: #c4bfb847;
	font-size: 13px;
	font-weight: 600;
	color: #4d4a4a;
	padding: 1px 6px;
	border-radius: 5px;
	margin-bottom: 4px;
	margin: 0 6px 3px 6px;
}

.Conversations-div .message.user .actions {
	display: flex;
	justify-content: end;
}

.Conversations-div .message.user .actions span {
	display: flex;
	align-items: center;
	font-size: 14px;
	font-weight: 500;
}

.Conversations-div .message.user .actions .date-time {
	font-size: 12px;
	font-weight: 500;
}

.Conversations-div .message.user .name {
	font-size: 14px;
	font-weight: 600;
}

/* staff */

.Conversations-div .message.staff .chat {
	display: inline-block;
	background: #8a6d851f !important;
	color: #333 !important;
	padding: 10px 15px;
	border-radius: 15px;
	min-width: 150px;
	max-width: 500px;
	text-align: left;
	font-size: 14px;
	font-weight: 500;
	line-height: 25px;
	margin-bottom: 7px;
}

.Conversations-div .message.staff .tag {
	background: #c4bfb847;
	font-size: 13px;
	font-weight: 600;
	color: #4d4a4a;
	padding: 1px 6px;
	border-radius: 5px;
	margin-bottom: 4px;
	margin: 0 6px 3px 6px;
}

.Conversations-div .message.staff .actions {
	display: flex;
	justify-content: start;
}

.Conversations-div .message.staff .actions span {
	display: flex;
	align-items: center;
	font-size: 14px;
	font-weight: 500;
}

.Conversations-div .message.staff .actions .date-time {
	font-size: 12px;
	font-weight: 500;
}

.Conversations-div .message.staff .name {
	font-size: 14px;
	font-weight: 600;
}

.dropdown-menu.staff-dropdown-menu {
	margin: -108px -116px !important;
}

/* custom domain model css */

.custom-domain-model p {
	font-size: 14px;
}

.custom-domain-model label {
	color: #1a1a1a;
	font-weight: 700;
	font-size: 14px;
	display: inline-block;
	margin-bottom: 0.5rem;
	padding: 0;
}

.custom-domain-model p {
	font-size: 13px;
	color: #00000082;
}

@media screen and (max-width: 1200px) {
	.message.user {
		margin-left: 60%;
	}
}

@media screen and (max-width: 1024px) {
	.message.user {
		margin-left: 55%;
	}

	.message.staff {
		width: 450px;
		margin-right: 90%;
		margin-top: 33px;
	}
}

@media screen and (max-width: 991px) {
	.message.user {
		margin-left: 50%;
	}
}

@media screen and (max-width: 768px) {
	.message.staff {
		width: 370px;
		margin-right: 90%;
		margin-top: 33px;
	}
}

@media screen and (max-width: 576px) {
	.message.user {
		margin-left: 15%;
	}

	.message.staff {
		width: 300px;
		margin-right: 90%;
		margin-top: 33px;
	}
}

@media screen and (max-width: 360px) {
	.Conversations-div .message.user .chat {
		padding: 6px 15px;
		border-radius: 10px;
		min-width: 90px;
		max-width: 500px;
	}

	.Conversations-div .message.user .chat {
		font-size: 13px;
		font-weight: 500;
		line-height: 18px;
	}

	.Conversations-div .message.user .actions span {
		justify-content: end;
	}

	.Conversations-div .message.user .actions {
		flex-wrap: wrap;
	}

	.Conversations-div .message.user .actions span {
		font-size: 13px;
	}

	.Conversations-div .message.user .actions .date-time {
		font-size: 11px;
	}

	.message.staff {
		width: 155px;
		margin-right: 90%;
		margin-top: 33px;
	}

	.Conversations-div .message.staff .chat {
		font-size: 13px;
		font-weight: 500;
		line-height: 18px;
	}

	.dropdown-menu.staff-dropdown-menu {
		margin: -108px -7px !important;
	}

	.staf-menu-dropdown-desc {
		font-size: 10px;
		margin-right: 4px;
	}
}

.chatboticon {
	background: #dee2e6ed;
	padding: 5px;
	object-fit: contain;
	border-radius: 5px;
}

.user-profile-img {
	width: 55px;
	height: 55px;
	border-radius: 100%;
}

.info-dropdown .dropdown-toggle::after {
	display: none;
}

.info-dropdown .btn-secondary {
	background-color: #9e3d8a;
	border-color: #9e3d8a;
	padding: 2px 2px !important;
	font-size: 12px;
	font-weight: 500;
	border-radius: 5px !important;
	margin-left: 0;
}

.info-dropdown .btn-secondary:hover {
	cursor: pointer;
	background-color: #854678;
	border-color: #854678;
}

.info-dropdown .btn-secondary:focus {
	cursor: pointer;
	background-color: #854678;
	border-color: #854678;
}

.cursor-pointer {
	cursor: pointer;
}

.tab-title {
	font-size: 18px;
	font-weight: 600;
	margin-bottom: 20px;
}

.text-danger {
	font-size: 13px !important;
	padding: 10px !important;
}

.cursor-pointer {
	cursor: pointer;
}

/* video clone page css */
.video-clone-input-container {
	transition: background-color 0.2s ease;
}

.video-clone-input-container:hover {
	background-color: #f0f0f0;
}

.video-clone-input-container.selected {
	border-color: #007bff;
	background-color: #e7f3ff;
}

.video-search-input-container {
	position: relative;
}

.max-height-400 {
	max-height: 300px;
}

.video-search-page .search-icon {
	top: 70px;
	right: 39px;
	font-size: 24px;
	cursor: pointer;
	transition: transform 0.3s ease-in;
}

.video-search-page .search-icon:hover {
	transform: translateX(-2px);
	color: #0a0ab7;
}

.video-search-page .form-control {
	padding: 10px;
	height: 45px;
}

.result-image-container {
	max-width: 120px;
	max-height: 100px;
	border-radius: 8px;
	overflow: hidden;
}

.result-image-container img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.result-container .selected {
	background-color: #e7f3ff;
	border: 1px solid #007bff !important;
}

.element-tab .element1 {
	border-radius: 25px;
	width: 100%;
	height: 40px;
}

.element-container {
	height: 600px;
	width: 100%;
}

.element-container .title-container {
	height: 5%;
}

.element-container .video-container-iframe {
	height: 399px;
	max-width: 709px;
}

.element-container .video-container-iframe iframe {
	height: 100%;
	max-width: 100%;
}

.element-tab1 svg {
	font-size: 24px;
}

.element-tab2 svg {
	font-size: 24px;
}

.element-tab1 .element {
	width: 75px;
	height: 75px;
}

.element-tab2 .element {
	width: 75px;
	height: 75px;
}

.element-tab1 .element:hover {
	border: 1px solid #000;
}

.element-tab2 p {
	font-weight: 600;
	font-size: 12px;
}

.cursor-pointer {
	cursor: pointer;
}

.delete-icon {
	font-size: 16px;
	color: rgb(218, 27, 27);
	margin-right: 8px;
}

.edit-image-container {
	width: 100px;
	height: 100px;
	overflow: hidden;
	border-radius: 4px;
}

.edit-image-container img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.edit-element-icon {
	font-size: 26px;
}

.option-box {
	width: 280px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 20px;
	color: #000;
}

.option-box-container {
	transition: all 0.3s ease-in;
}

.option-box-container:hover {
	cursor: pointer;
	background-color: #c5d1df;
	border: 1px solid #000;
}

.div-icon-box {
	font-size: 40px;
}

.preview-player-information-container {
	margin-top: 20px;
}

/* stock image search csss */
.stock_model .modal-dialog {
	min-width: 900px;
}

.stock_model .search-icon {
	top: 65px;
	right: 36px;
	font-size: 24px;
}

.vc-box {
	background-color: #fff;
	border-radius: 10px;
	overflow: hidden;
	margin-bottom: 10px;
	position: relative;
	padding: 15px;
	transition: all 0.5s;
	box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.15);
	border: 1px solid #0041b473 !important;
}

.vc-box:hover {
	cursor: pointer;
	box-shadow: 0px 2px 5px 1px rgb(140, 169, 221);
}

.vc-box .vc-media {
	width: 100%;
	height: 200px;
	position: relative;
	transition: all 0.5s;
	overflow: hidden;
	border-radius: 10px;
}

.vc-box .vc-media::before {
	content: "";
	position: absolute;
	bottom: 0;
	left: 0;
	height: 100%;
	width: 100%;
	transition: all 0.5s;
	background-color: rgba(0, 0, 0, 0.8);
	opacity: 0;
}

.vc-box:hover .vc-media::before {
	opacity: 1;
	transition: all 0.5s;
}

.vc-box .vc-media img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	transition: all 0.5s;
}

.vc-box:hover .vc-media img {
	transform: scale(1.1);
	filter: grayscale(2);
}

.vc-box .vc-btn {
	position: absolute;
	right: 50%;
	bottom: -50px;
	transform: translateX(50%) scale(0);
	display: flex;
	align-items: center;
	justify-content: center;
	transition: all 0.5s;
}

.vc-box:hover .vc-btn {
	bottom: 60px;
	transform: translateX(50%) scale(1);
}

/* ai copy writer css */
.card-footer {
	display: flex;
	background-color: #519af814;
	border: none;
	padding: 13px 10px 13px 15px;
}

.custom-btn {
	padding: 4px 0px !important;
	width: 8rem !important;
}

/* ai stock */

.ai-stock-card {
	transition: all 0.3s linear;
}

.ai-stock-icon-box {
	width: 172px;
	height: 173px;
}

.ai-stock-icon-box img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	transition: all 0.3s linear;
}

.ai-stock-card:hover .ai-stock-icon-box img {
	transform: scale(1.1);
}

.ai-stock-button {
	padding: 3px 10px !important;
	font-size: 15px !important;
	border-radius: 12px !important;
}

.ai-stock-card-title {
	font-size: 17px !important;
	font-weight: 700 !important;
}

.ai-stock-load-more {
	font-size: 14px !important;
}

.ai-audio-box {
	position: relative;
	padding: 20px;
	background-color: #fff;
	border: 1px solid rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	margin-bottom: 20px;
	transition: all 0.5s;
}

.ai-audio-box .ai-media {
	width: 100%;
	height: 70px;
	position: relative;
	margin-bottom: 10px;
}

.ai-audio-box .ai-media img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.ai-audio-box .ai-media .icon-box {
	border: 0px !important;
	position: absolute;
	top: 5px;
	opacity: 0;
	transition: all 0.5s;
	background: transparent;
	width: 100%;
	display: flex;
	align-items: baseline;
	justify-content: start;
	text-align: end;
	left: 5px;
	height: auto;
}

.ai-audio-box audio {
	width: 100%;
}

.ai-audio-box .ai-image-name {
	margin-top: 10px;
	padding: 10px;
	border-radius: 10px;
	background-color: var(--body-bg);
}

.ai-audio-box:hover {
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
	transition: all 0.5s;
}
.ai-audio-box:hover .icon-box {
	opacity: 1;
}
.aivideo-box {
	position: relative;
	overflow: hidden;
	margin-bottom: 20px;
	height: 195px;
	border: 1px solid var(--primary-color);
	padding: 5px;
	border-radius: 9px;
  }.aivideo-box .icon-box {
	border: 0px !important;
	position: absolute;
	top: 5px;
	opacity: 0;
	transition: all 0.5s;
	background: transparent;
	width: 100%;
	display: flex;
	align-items: baseline;
	justify-content: start;
	text-align: end;
	left: 5px;
	height: auto;
  }.aivideo-box:hover .icon-box {
	opacity: 1;
	transition: all 0.5s;
  }
  /* custom domain icon */
.add-custom_domain{
	font-size: 27px;
	color: var(--primary-color);
	cursor: pointer;
	transition: all 0.3s ease-in;
}
.add-custom_domain:hover{
	color: var(--secondary-color);
	transform: rotate(50deg);
}

/* chatbot css */
.chatbot-delete-icon{
	color: rgb(151, 17, 17);
	font-size: 20px;
}