.upgrade-box {
    padding: 0;
    background-color: #fff;
    margin-bottom: 20px;
    border-radius: 20px;
    transition: all 0.5s;
    box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.15);
}
.upgrade-box:hover{
    cursor: pointer;
    box-shadow: 0px 2px 5px 1px rgba(214, 0, 170, 0.39);
}

.upgrade-box .media {
    height: 200px;
    width: 100%;
    padding: 20px;
    margin-bottom: 10px;
}
.upgrade-box .media img{
    border-radius: 10px;
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.upgrade-box .content {
    text-align: center;
    border-top: 1px dashed #737879c4;
    padding: 20px 15px;
}

.upgrade-box .content .title{
    margin-bottom: 20px;
    font-size: 15px;
    font-weight: 600;
}
.upGradeBtn {
    font-weight: 400;
    padding: 10px 15px;
    border-radius: 0.3rem;
    font-size: 15px;
    line-height: 1;
}
.upGradeBtn-primary {
    color: #fff !important;
    background-color: #188ae2;
    border-color: #188ae2;
    box-shadow: 0px 5px 10px 0 rgb(24 138 226 / 40%);
}
