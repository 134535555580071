.all-site-template {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0px;
    border-radius: 15px;
    padding: 10px;
    box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.15);
    overflow: hidden;
  }
  .all-site-template:hover {
    cursor: pointer;
  }
  .all-site-template .template-media {
    position: relative;
    overflow: hidden;
    border-radius: 5px;
  }
  .all-site-template img {
    height: 260px;
    object-fit: contain;
    background: #1d598e1a;
    padding: 10px 10px;
    border-radius: 5px;
    position: relative;
  }
  .all-site-template .template-media::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 0;
    width: 100%;
    transition: all 0.5s;
    background-color: rgba(74, 79, 83, 0.69);
    opacity: 1;
    z-index: 1;
  }
  .all-site-template:hover .template-media::before {
    height: 100%;
    transition: all 0.7s;
  }
  .all-site-template .template-content {
    padding: 10px 5px 5px 5px;
  }
  .all-site-template .pos-tops {
    position: absolute;
    top: 5px;
    right: 10px;
    overflow: hidden;
    z-index: 1;
    transform: skew(-12deg, 0deg);
  }
  .all-site-template .badge-style {
    position: relative;
    display: block;
    padding: 5px 15px 5px 15px;
    border-radius: 3px;
    font-size: 12px;
    color: #fff;
  }
  .all-site-template .template-title {
    text-align: center;
  }
  .all-site-template .template-title span {
    color: #000000e0;
    font-weight: 600;
    font-size: 16px;
  }
  .all-site-template .over-layer {
    position: absolute;
    bottom: 65px;
    left: 35%;
    opacity: 0;
    transition: all 0.7s;
    overflow: hidden;
    z-index: 1;
  }
  .all-site-template:hover .over-layer {
    bottom: 50%;
    opacity: 1;
  }
  .modal-header {
    padding: 10px 17px !important;
  }