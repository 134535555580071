/* App.css */
.chat-container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	height: 640px;
	background-color: #fff;
	color: #000;
	box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.04);
	border-radius: 16px;
	/* padding: 15px 15px; */
	overflow: hidden;
	position: relative;
	opacity: 0.8;
}

.chat-box {
	margin-top: 30px;
	margin-bottom: 30px;
	padding: 10px 15px;
	width: 100%;
	height: 430px;
	overflow: scroll;
	scrollbar-color: transparent transparent;
	scrollbar-width: none;
	display: flex;
	flex-direction: column;
	z-index: -2;
}

.chat-box .staff-message {
	width: 550px;
	margin-right: 90%;
	margin-top: 15px;
}

.chat-box .staff-message p {
	background: #362c59fc;
	padding: 20px 15px;
	font-size: 15px;
	line-height: 22px;
	text-align: left;
	border-radius: 40px;
	color: #fff;
}

.chat-box .user-message {
	max-width: 485px;
	min-width: 50px;
	margin-left: 55%;

}

.chat-box .user-message p {
	background: #9252baeb;
	padding: 15px 15px;
	font-size: 15px;
	line-height: 22px;
	border-radius: 40px;
	margin-bottom: 10px !important;
	color: #fff;
}

.input-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: linear-gradient(90deg, rgb(115, 34, 168) 0%, rgb(136, 55, 174) 100%);
	padding: 15px 15px;
	width: 100%;
	box-shadow: rgba(0, 0, 0, 0.18) 0px 1px 5px 0px;
}

.multi-select {
	flex: 1;
	margin-right: 10px;
}

.input-container .instuction-text {
	color: #fff;
	font-size: 12px;
	padding-left: 5px;
}

.input-container input {
	background-color: transparent;
	border: none;
	outline: none;
	color: #fff !important;
	font-weight: 500;
	width: 50%;
}

.input-container input::placeholder {
	color: #fff;
}

.send-icon {
	color: #fff;
	font-size: 25px;
	cursor: pointer;
}

.custom__control {
	background-color: #511177 !important;
	border: 1px solid #9352ba !important;
	box-shadow: rgba(0, 0, 0, 0.08) 0px 1px 5px 0px;
	width: 300px;
	border-radius: 25px !important;
}

.css-1jqq78o-placeholder {
	color: #fff !important;
}

.custom__control:hover {
	border-color: #888;
}

.css-1xc3v61-indicatorContainer:hover {
	color: #fff !important;
}

.custom-menu {
	background-color: #000 !important;
}

.user-name {
	font-size: 13px;
	font-weight: 600;
	color: #000;
}

.img-sm {
	max-width: 30px;
	margin: 0 7px;
	border-radius: 100%;
	background-color: #000;
	height: 30px;
	max-height: 30px;
}

.main-row {
	height: 600px;
	display: flex;
	align-items: center;
}

.middle-box {
	background: #1a17142e;
	padding: 80px;
	border-radius: 12px;
}

.custom__control .custom-menu {
	border-radius: 15px !important;
}

.custom {
	padding: 0px 0px !important;
}

.custom__menu {
	background-color: #000;
	/* Set dropdown background color */
	padding: 0px 0px !important;
}

.custom__option {
	background-color: rgb(136, 55, 174) !important;
	color: #fff !important;

}

.chat-front-view {
	width: 100%;
}

.chat-front-view .heading {
	text-align: center;
	font-size: 18px;
	font-weight: 600;
	color: #000;
}

.heading {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	background: linear-gradient(90deg, rgb(115, 34, 168) 0%, rgb(136, 55, 174) 100%);
	padding: 10px 15px;
	box-shadow: rgba(255, 249, 249, 0.84) 0px 0px 3px 0px;
}

.project-name {
	color: #fff;
	font-weight: 600;
	margin-bottom: 0px;
}

.heading-area .main-title {
	font-size: 32px;
	font-weight: 700;
	color: #000;
}

.heading-area .sub-text {
	font-size: 18px;
	font-weight: 500;
	color: #000;
}

.heading-area .tags-text span {
	color: rgb(51, 15, 75);
	font-weight: 600;
	font-size: 13px;
}

.tag-block {
	display: flex;
	align-items: center;
	padding-left: 15px;
	width: 320px;
	flex-wrap: wrap;
	justify-content: flex-start;
}

.tag-block span {
	font-size: 15px;
	font-weight: 600;
}

.tag-block .tags-text {
	background-color: #d3d3d36b;
	font-size: 13px !important;
	font-weight: 600 !important;
	padding: 7px 12px !important;
	color: #000000d6;
	margin: 0px 5px 5px 5px !important;
	border-radius: 40px;
}

.icon-fill {
	color: #fff;
	padding: 5px;
	font-size: 22px;
	margin: 0 3px;
	border-radius: 5px;
}

.bg-secondary {
	background-color: var(--secondary-color) !important;
}

.icon-fill.copy-btn {
	background-color: var(--secondary-color) !important;
}

.icon-fill:hover.copy-btn {
	background-color: var(--primary-color) !important;
}

.icon-fill.delete-btn {
	background-color: rgb(221, 50, 67) !important;
}

.icon-fill:hover.delete-btn {
	background-color: red !important;
}



/* dropdown */

.menu-toggle .dropdown-toggle::after {
	display: none;
}

.menu-toggle .toggle-btn {
	padding: 0px 5px 5px 6px !important;
	border-radius: 6px !important;
	background: rgb(154, 1, 165);
	color: #fff;
	border-color: rgb(154, 1, 165);
}

.css-1p3m7a8-multiValue {
	background-color: hsl(278.6, 42.3%, 53.1%) !important;
	border-radius: 18px !important;
}

.css-9jq23d {
	color: #fff !important;
}

.click-show-data {
	position: relative;
	display: inline-block;
}

.click-show-data .dropdown-button {
	padding: 6px 8px;
	font-size: 18px;
	cursor: pointer;
	background: #511177 !important;
	color: white;
	border: 1px solid #ffffff85;
	border-radius: 8px;
	margin-right: 10px;
	line-height: 18px;
}

.click-show-data .dropdown-content {
	display: none;
	/* Initially hidden */
	position: absolute;
	top: 40px;
	left: -115px;
	background-color: #fff;
	min-width: 240px;
	padding: 10px;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.11);
	border-radius: 10px;
	z-index: 1;
}

.button2 .dropdown-button {
	padding: 6px 8px;
	font-size: 18px;
	cursor: pointer;
	background: #511177 !important;
	color: white;
	border: 1px solid #ffffff85;
	border-radius: 8px;
	line-height: 18px;
}

.button2 .dropdown-button:hover{
	background: #51236c !important;
	cursor: pointer;
}

.button2 .inner-dropdown-content {
	position: absolute;
	top: -95px;
	right: -12px;
	background-color:#e4d8ea;
	min-width: 70rem;
	padding: 15px 15px;
	box-shadow: 0px 4px 4px 0px rgba(146, 82, 186, 0.57);
	border-radius: 0px 0px 13px 13px;
	z-index: -1;
	transition: all 0.7s;
	height: 130px;
	overflow-y: scroll !important;
	overflow-x: hidden;
	scrollbar-width: none;
	scrollbar-color: #9756bb #ba92d25e;
}

.button2:hover .inner-dropdown-content {
	opacity: 1;
	top: 40px;
}

.chat-addition {
	display: inline-flex;
	align-items: center;
	justify-content: space-between;
	/* background: #9453bac9; */
	background: #fff;
	border-radius: 8px;
	padding: 1px 6px;
	margin-bottom: 5px;
	box-shadow: 0px 1px 4px 0px rgba(146, 82, 186, 0.57);
}

.chat-dropdown .btn {
	padding: 5px 5px !important;
}

.chat-dropdown .dropdown-toggle::after {
	display: none;
}

.inner-dropdown-content .inner-content {
	display: inline-block;
	padding: 0px 3px;
	border-radius: 5px;
	color: #000;
	font-size: 14px;
	font-weight: 600;
	margin-bottom: 0;
	margin-right: 5px;
}

.chat-addition:hover {
	background: #9c5bbd91;
	cursor: pointer;
}

.chat-addition:hover .inner-content {
	color: #fff;
}
.chat-addition:hover  .chat-dropdown .btn{
	color: #fff;
}
.btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check)+.btn:active {
	color: var(--bs-btn-active-color);
	background-color: var(--bs-btn-active-bg);
	border-color: transparent;

}

.new-chat {
	padding: 6px 8px;
	font-size: 18px;
	cursor: pointer;
	background: #511177 !important;
	color: white;
	border: 1px solid #ffffff85;
	border-radius: 8px;
	line-height: 18px;
}
.new-chat:hover{
	background: #51236c !important;
}

.dropdown-menu {
	margin: 5px 0px !important;
}
.loader-2{
	width: 25px;
	height: 25px;
}
@media screen and (max-width: 1400px) {
	.button2 .inner-dropdown-content {
		right: -15px;
		background-color: #9453ba5e;
		min-width: 69rem;
	}
}

@media screen and (max-width: 1200px) {
	.chat-container {
		height: 545px;
	}

	.button2 .inner-dropdown-content {
		right: -8px;
		background-color: #e1d8e6;
		min-width: 56.3rem;
	}

	.input-container input {
		width: 45%;
	}
}

@media screen and (max-width: 1024px) {
	.button2 .inner-dropdown-content {
		right: -15px;
		min-width: 46.3rem;
	}

	.input-container input {
		width: 35%;
	}

	.custom__control {
		width: 280px;
	}

	.chat-box .staff-message {
		width: 430px;
		margin-right: 83%;
		margin-top: 35px;
	}

	.tag-block span {
		font-size: 12px;
		font-weight: 600;
	}
}

@media screen and (max-width:991px) {
	.button2 .inner-dropdown-content {
		right: -14px;
		min-width: 40.9rem;
	}

	.custom__control {
		width: 245px;
	}
}

@media screen and (max-width: 768px) {
	.button2 .inner-dropdown-content {
		right: -14px;
   		 min-width: 38.3rem;
	}

	.chat-box .user-message {
		max-width: 515px;
		min-width: 66px;
		margin-left: 45%;
	}
}

@media screen and (max-width: 576px) {
	.heading{
		padding: 10px 7px;
	}
	.heading .project-name{
		font-size: 13px;
	}
	.button2 .inner-dropdown-content {
		right: -5px;
   		 min-width: 26.3rem;
	}

	.input-container {
		flex-wrap: wrap;
	}

	.input-container input {
		width: 60%;
		margin-top: 8px;
		font-size: 13px;
	}

	.custom__control {
		width: 425px;
		margin-bottom: 5px;
	}

	.chat-box .user-message {
		max-width: 515px;
		min-width: 66px;
		margin-left: 18%;
	}

	.chat-box .staff-message {
		width: 370px;
		margin-right: 83%;
		margin-top: 35px;
	}
}

@media screen and (max-width: 360px) {
	
	.button2 .inner-dropdown-content {
		right: -5px;
		min-width: 16.5rem;
	  }
	.right-container.m-responsive{
		padding: 90px 0px 10px 0px !important;
	}
	.project-name{
		margin-bottom: 0px;
		font-size: 13px;
	}

	.heading{
		flex-wrap: wrap;
	}

	.chat-container {
		height: 545px;
	}

	.input-container{
		padding: 10px 10px;
	}

	.chat-box .user-message p {
		padding: 6px 12px;
	}

	.chat-box .user-message {
		margin-left: 10%;
	}

	.img-sm {
		max-width: 17px;
		margin: 0 7px;
		border-radius: 100%;
		height: 17px;
		max-height: 30px;
	}
	
	.user-name {
		font-size: 11px;
		font-weight: 700;
		color: #000;
	}

	.new-chat{
		padding: 2px 3px;
		margin-top: 8px;
	}

	.button2 .dropdown-button{
		padding: 3px 4px;
		line-height: 16px;
		margin-top: 8px;
	}

	.heading-area .main-title {
		font-size: 19px;
	}

	.heading-area .sub-text {
		font-size: 14px;
		margin-bottom: 2px;
	}

	.heading-area .tags-text span {
		font-size: 11px;
	}

	.custom__control {
		width: 215px;
		margin-bottom: 5px;
	}

	.input-container input {
		width: 100%;
		margin-bottom: 7px;
		margin-top: 5px;
	}

	.css-1jqq78o-placeholder {
		color: #fff !important;
		font-size: 13px;
	}

	.input-container input::placeholder {
		font-size: 14px;
	}
	.tag-block .tags-text {
		font-size: 12px !important;
		font-weight: 600 !important;
		padding: 4px 7px !important;
		color: #000;
		margin: 0px 5px 5px 5px !important;
		border-radius: 40px;
	  }

	  .tag-block{
		padding-left: 0px;
	  }
	  .chat-box .staff-message {
		width: 215px;
		margin-right: 83%;
		margin-top: 35px;
	  }
	  .loader-2{
		width: 20px;
		height: 20px;
	}
}

.pointer {
	cursor: pointer;
}
.fw-800{
	font-weight: 600;
}
.f25{
	font-size: 25px;
}

